import { useRef, useState } from "react";
import "./App.css";
import axios from "axios";
import SignaturePad from "react-signature-canvas";

function App() {
  const canvas = useRef(null);
  const [signature, setSignature] = useState([]);
  const [resname, setResname] = useState("");
  const [resdoctype, setResdoctype] = useState("");
  const [resdoc, setResdoc] = useState("");
  const [patientname, setPatientname] = useState("");
  const [patientdoctype, setPatientdoctype] = useState("");
  const [patientdoc, setPatientdoc] = useState("");

  var send = {
    NombreResponsable: resname,
    TipoDocResponsable: resdoctype,
    DocResponsable: resdoc,
    NombrePaciente: patientname,
    TipoDocPaciente: patientdoctype,
    DocPaciente: patientdoc,
    Firma: signature,
  };

  const Save = () => {
    const base64 = canvas.current.toDataURL();
    setSignature(base64);
  };

  const Clear = () => {
    canvas.current.clear();
    setSignature([]);
  };

  const Send = () => {
    if (
      resname === "" ||
      resdoctype === "" ||
      resdoc === "" ||
      patientname === "" ||
      patientdoctype === "" ||
      patientdoc === "" ||
      signature.length === 0
    ) {
      alert("Ingrese todos los datos.");
    } else {
      axios
        .post(
          "https://consentimiento-informado.riie.com.co/server/consentimiento.php",
          send
        )
        .then((response) => alert(response.data.msj));
      window.location.replace("https://www.riie.com.co/", "_blank");
    }
  };

  return (
    <div className="App">
      <h1>Formulario de consentimiento informado</h1>
      <center>
        <strong>CONSENTIMIENTOS INFORMADOS RIIE</strong>
      </center>
      <br />
      Yo,{" "}
      <input
        placeholder="Ingrese el nombre del representante legal"
        type="text"
        onChange={(e) => setResname(e.target.value)}
      />{" "}
      , identificado (a) con documento de identificación{" "}
      <select onChange={(e) => setResdoctype(e.target.value)}>
        <option hidden>Seleccione el tipo de documento del responsable</option>
        <option>Registro civil</option>
        <option>Tarjeta de identidad</option>
        <option>Cédula de ciudadania</option>
        <option>Cédula de extranjeria</option>
        <option>Pasaporte</option>
        <option>Certificado de nacido vivo</option>
      </select>{" "}
      número{" "}
      <input
        placeholder="Ingrese el número de documento del representante legal"
        onChange={(e) => setResdoc(e.target.value)}
      />
      , actuando en nombre propio o como representante legal de{" "}
      <input
        placeholder="Ingrese el nombre del paciente"
        onChange={(e) => setPatientname(e.target.value)}
      />
      con documento de identificación{" "}
      <select onChange={(e) => setPatientdoctype(e.target.value)}>
        <option hidden>Seleccione el tipo de documento del paciente</option>
        <option>Registro civil</option>
        <option>Tarjeta de identidad</option>
        <option>Cédula de ciudadania</option>
        <option>Cédula de extranjeria</option>
        <option>Pasaporte</option>
        <option>Certificado de nacido vivo</option>
      </select>{" "}
      número{" "}
      <input
        placeholder="Ingrese el número de documento del paciente"
        onChange={(e) => setPatientdoc(e.target.value)}
      />
      , acepto los siguientes consentimientos y declaro:
      <br />
      <br />
      <center>
        <strong>CONSENTIMIENTOS INFORMADOS PARA LA ATENCIÓN</strong>
      </center>
      <br />
      •Que soy mayor de edad y actúo en nombre propio o como representante legal
      del menor de edad mencionado anteriormente.
      <br />
      <br />
      •Que firmo este consentimiento para los servicios prestados a mí o a mi
      representado, según los riesgos y procedimientos específicos que pueda
      requerir cada terapia.
      <br />
      <br />
      •Que he sido informado por Rehabilitación Integral Infantil Especializada
      - RIIE sobre el tratamiento y modalidad de atención considerados adecuados
      para mí o para mi representado.
      <br />
      <br />
      •Que el tratamiento y consulta se realizará previo el análisis de los
      antecedentes, datos de historia clínica y examen físico, de mi
      representado, se ordenarán, si es necesario, exámenes de laboratorios o
      complementarios, relacionados al motivo de consulta (caso de Pediatría y
      Dermatología).
      <br />
      <br />
      •Para Pediatría y Dermatología: Entiendo que, si no informo adecuadamente
      y con la verdad todos los datos necesarios, se puede ocasionar confusiones
      en el diagnostico o errores de selección de los tratamientos, sin que
      estos resultados sean atribuibles al médico. Así, como si decido no
      realizar los exámenes diagnósticos ordenados a mi representado, o no
      cumplo los tratamientos ordenados y / o no acepto las intervenciones
      sugeridas, que pueden presentarse reacciones adversas, ajenas al actuar
      profesional, sin que ello signifique pérdida de mis derechos ni de los de
      mi representado.
      <br />
      <br />
      •Para la Evaluación Integral de Riesgo: He sido informado por RIIE sobre
      la ruta integral de atención para la promoción y mantenimiento de la salud
      para la población infantil y adolescente, según la resolución 3280/18 del
      Ministerio de Salud y Protección Social.
      <br />
      <br />
      •Que se me ha informado que la atención de servicio es integral y puede
      requerir, según la condición clínica, de procedimientos de diferente
      naturaleza como:
      <br />
      <br />
      <strong>Fonoaudiología:</strong>
      <br />
      •Entrenamiento a padres y /o cuidadores
      <br />
      •Actividades Comunicación (No verbal y Verbal)
      <br />
      •Desarrollo cognitivo orientado a la vinculación del menor al aula regular
      <br />
      •Actividades de función oro motor (Miofuncional)
      <br />
      •Dispositivos básicos de aprendizaje: atención, motivación, memoria,
      sensopercepción, habituación.
      <br />
      •Actividades Alimentación (Alimento: Textura/Consistencia, Aditamentos,
      Respiración)
      <br />
      <br />
      <strong>Terapia Ocupacional:</strong>
      <br />
      •Entrenamiento a padres y /o cuidadores
      <br />
      •Actividades de Motricidad Fina (sensibilidad, agarres, patrones
      funcionales, movimientos en base inestable, identificación de esquema
      corporal, orientación temporoespacial)
      <br />
      •Desarrollo cognitivo orientado a la vinculación del menor al aula regular
      <br />
      •Actividades Alimentación (Alimento: Textura/Consistencia, Aditamentos)
      <br />
      •Actividades y autonomía de la vida diaria · Dispositivos básicos de
      aprendizaje: atención, motivación, memoria, sensopercepción, habituación.
      <br />
      •Estimulación multisensorial (táctil, vestibular, propioceptivo, visual y
      auditivo
      <br />
      <br />
      <strong>Psicología y Neuropsicología</strong>
      <br />
      •Entrenamiento a padres y/o cuidadores
      <br />
      •Asesoría familiar: pautas de crianza, dificultades del comportamiento y
      Desarrollo cognitivo orientado a la vinculación del menor al aula regular
      <br />
      •Dispositivos básicos de aprendizaje: atención, motivación, memoria,
      sensopercepción, habituación.
      <br />
      •Psicoterapia e intervención psicológica individual
      <br />
      <br />
      <strong>Rehabilitación Cognitiva:</strong>
      <br />
      •Entrenamiento a padres y /o cuidadores
      <br />
      •Desarrollo cognitivo orientado a la vinculación del menor al aula regular
      <br />
      •Dispositivos básicos de aprendizaje: atención, motivación, memoria,
      sensopercepción, habituación.
      <br />
      <br />
      <strong>Terapia física:</strong>
      <br />
      •Actividades de Motricidad Gruesa (tono, reacciones automáticas básicas,
      patrones de postura y movimiento)
      <br />
      •Hidrocinesiterapia orientada al balance, control postural y estiramiento
      <br />
      •Estímulos propioceptivos superficiales y profundos (REB, Vojta y
      Kinesio-Taping)
      <br />
      •Entrenamiento a padres y /o cuidadores Especialidades médicas (Pediatría,
      Dermatología, Neuropediatría, Fisiatría y Nutrición)
      <br />
      •Tratamiento(s) o procedimiento(s) que se consideren idóneos para tratar
      de solucionarlo o aliviarlo según su diagnóstico.
      <br />
      •Exámenes de laboratorios o complementarios, relacionados al motivo de
      consulta Evaluación Individual de Riesgo (EIR)
      <br />
      •Durante la consulta se realizará la aplicación de tamizajes que
      determinan si hay alguna alerta dentro de su desarrollo para poder
      prevenir a tiempo y disminuir el riesgo de padecer de alguna enfermedad.
      <br />
      •Los tamizajes se aplican de acuerdo con la edad del niño/joven e incluyen
      varias pruebas desde las áreas de Enfermería, Trabajo Social y Psicología.
      Algunas pruebas se realizan en modalidad virtual y otras presenciales.
      <br />
      •La atención en este servicio es integral y se realizará el seguimiento
      conforme a lo establecido en la resolución.
      <br />
      <br />
      •Que teniendo en cuenta la condición de salud y el plan de intervención
      del programa de que se va a realizar, se me hizo advertencia de los
      siguientes riesgos previstos:
      <br />
      <br />
      <strong>Fonoaudiología</strong>
      <br />
      •Broncoaspiración
      <br />
      •Lesión cavidad oral
      <br />
      •Aspiración de aplicadores o baja lenguas
      <br />
      •Caídas/golpes por los traslados y/o desplazamientos
      <br />
      •Náuseas, ansiedad
      <br />
      •Vómito
      <br />
      •Convulsiones
      <br />
      •Otros
      <br />
      <br />
      <strong>Terapia Ocupacional</strong>
      <br />
      •Lesiones osteomioarticulares
      <br />
      •Broncoaspiración
      <br />
      •Lesión cavidad oral
      <br />
      •Caídas/golpes por los traslados y/o desplazamientos
      <br />
      •Náuseas, ansiedad
      <br />
      •Irritación/laceración piel
      <br />
      •Vómito
      <br />
      •Convulsiones
      <br />
      <br />
      <strong>Psicología y Neuropsicología</strong>
      <br />
      •Caídas/golpes por los traslados y/o desplazamientos / Náuseas, ansiedad
      <br />
      •Convulsiones
      <br />
      •Otros
      <br />
      <br />
      <strong>Rehabilitación Cognitiva</strong>
      <br />
      •Caídas/golpes por los traslados y/o desplazamientos· Náuseas, ansiedad
      <br />
      •Convulsiones
      <br />
      •Otros
      <br />
      <br />
      <strong>Terapia física</strong>
      <br />
      •Lesiones osteomioarticulares
      <br />
      •Caídas/golpes por los traslados y/o desplazamientos
      <br />
      •Náuseas, ansiedad / Irritación/laceración piel
      <br />
      •Vómito
      <br />
      •Convulsiones Especialidades medica: (Pediatría, Dermatología,
      Neuropediatría, Fisiatría y Nutrición)
      <br />
      •Caídas/golpes por los traslados y/o desplazamientos
      <br />
      •Náuseas, ansiedad
      <br />
      •Irritación/laceración piel
      <br />
      •Convulsiones
      <br />
      •Otros
      <br />
      <br />
      <strong>Evaluación Individual de Riesgo (EIR)</strong>
      <br />
      •Caídas/golpes por los traslados y/o desplazamientos
      <br />
      •Náuseas, ansiedad
      <br />
      •Convulsiones
      <br />
      •Otros
      <br />
      <br />
      •Se me ha dado la oportunidad de preguntar y aclarar las dudas generadas
      sobre la atención en el servicio, por lo que he recibido la información a
      satisfacción sobre la atención prestada de manera virtual. Además, se me
      explicó la importancia de supervisar y acompañar permanentemente al menor
      durante el tiempo de la atención.
      <br />
      <br />
      •Por lo anterior doy constancia de haber sido informado a satisfacción
      sobre el proceso de rehabilitación y evaluación que se ha determinado, y
      doy mi consentimiento para que se me expliquen los procedimientos propios
      de este tipo de atención, entendiendo y aceptando los posibles riesgos de
      complicaciones que estos pueden implicar.
      <br />
      <br />
      •Autorizo a la Institución el servicio cerrado de televisión (modalidad
      presencial) como medio de protección para mi hijo/hija y acepto que las
      fotos y videos que sean tomadas al menor (en modalidad presencial y
      virtual) sean utilizadas exclusivamente con fines científicos, académicos,
      investigativos y testimoniales para acreditar la atención prestada por
      este medio.
      <br />
      <br />
      •Me han informado de mi derecho a rechazar el tratamiento o revocar este
      consentimiento y de las consecuencias posibles de esta determinación.
      <br />
      <br />
      <center>
        <strong>
          CONSENTIMIENTO INFORMADO PARA LA ATENCIÓN EN LAS SEDES DE RIIE DURANTE
          LA EMERGENCIA SANITARIA POR COVID-19
        </strong>
      </center>
      <br />
      <br />
      <center>
        <strong>DECLARO QUE</strong>
      </center>
      <br />
      <br />
      •Soy mayor de edad y actúo en nombre propio o como representante del menor
      de edad, cuyos datos se encuentran en las preguntas anteriores.
      <br />
      <br />
      •Que he sido informado por Rehabilitación Integral Infantil Especializada
      - RIIE sobre la modalidad de atención en sede durante LA EMERGENCIA
      SANITARIA COVID-19; dentro del plan de tratamiento considerado para el
      diagnóstico médico de mí o de mi representado.
      <br />
      <br />
      •Reconozco que el virus COVID-19 tiene un período de incubación durante el
      cual sus portadores pueden estar asintomáticos, siendo altamente
      contagioso. Así como que, al momento, debido a las limitaciones para la
      realización de las pruebas virales, es imposible determinar quién es
      portador del virus y quién no.
      <br />
      <br />
      •Entiendo que, a pesar del seguimiento de normas de bioseguridad en la IPS
      a la presencia de otros pacientes, a las características del virus y de
      los hábitos propios de higiene, existe un riesgo elevado de contraer el
      virus por el solo hecho de permanecer en la IPS.
      <br />
      <br />
      •Confirmo que mi hijo no presenta, ni ha presentado en los últimos 14
      días, ninguno de los síntomas de COVID-19 de la siguiente lista: fiebre,
      dificultad respiratoria, tos seca, secreción nasal, dolor de garganta. Así
      como tampoco algún miembro de mi núcleo familiar.
      <br />
      <br />
      •Declaro que no hemos estado en contacto con alguna persona con
      confirmación de COVID-19 o con cuadro respiratorio agudo en los últimos
      siete (7) días
      <br />
      <br />
      Me han informado de mi derecho a rechazar el tratamiento o revocar este
      consentimiento y de las consecuencias posibles de esta determinación, de
      esta manera con mi firma acepto los consentimientos aquí explicados
      <center>
        <strong>REGIMEN GENERAL DE PROTECCIÓN DE DATOS PERSONALES</strong>
      </center>
      <br />
      <br />
      Ley 1581 de 2012: de protección de datos personales, es una ley que
      complementa la regulación vigente para la protección del derecho
      fundamental que tienen todas las personas naturales a autorizar la
      información personal que es almacenada en bases de datos o archivos, así
      como su posterior actualización y rectificación.
      <br />
      <br />
      Rehabilitación Integral Infantil Especializada en los términos dispuestos
      por el artículo 10 del decreto 1377 de 2013 queda autorizada de manera
      expresa inequívoca para mantener y manejar toda su información, a no ser
      que usted le manifieste lo contrario de manera directa; expresa inequívoca
      y por escrito dentro de los (30) días hábiles contados a partir de la
      recepción de la presente comunicación a la cuenta de correo electrónico
      dispuesta para tal efecto: calidad@riie.com.co
      <br />
      <br />
      En el evento en que se considere que la IPS dio un uso contrario al
      autorizado y a las leyes aplicables, podrá contactarnos a través de una
      comunicación motivada a la Coordinación de Calidad de la IPS:
      calidad@riie.com.co
      <br />
      <br />
      La política de protección y tratamiento de datos personales esta publicada
      en la página web de RIIE y en la plataforma transaccional de cada usuario
      para su consulta.
      <br />
      <br />
      Por favor introduzca su firma en el siguiente recuadro.
      <br />
      <br />
      <div className="signature-pad">
        <SignaturePad
          canvasProps={{ className: "signature" }}
          ref={canvas}
          onEnd={Save}
        />
      </div>
      <center>
        <button onClick={Clear}>Borrar</button>
        <button onClick={Send}>Enviar</button>
      </center>
    </div>
  );
}

export default App;
